import {Dispatch, SetStateAction} from 'react'
import {DateTime, String} from '../../../app/modules/auth'

export interface PaginationState {
  page?: number | undefined
  currentPage?: number | undefined
  perPage?: number | undefined
  totalPage?: number | undefined
  totalData?: number | undefined
  links?: {
    active: boolean
    disabled: boolean
    label: string | undefined
    page: number | undefined
  }[]
}

export interface LoadMoreState extends Omit<PaginationState, 'perPage'> {
  lastTime?: DateTime
}
export interface SortState {
  availableSort?: string
  optionSort?: string
}

export interface FilterState {
  filter?: unknown
}

export interface SearchState {
  search?: string
}

export interface Response<T> {
  data?: T
  message?: string
  statusCode?: number
  currentPage?: number
  totalPage?: number
  perPage?: number
  totalData?: number
}
export interface ResponseGraphQL<T> {
  data?: T
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & LoadMoreState

export interface QueryRequestContextProps {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  perPage: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export interface PaginationQueryResponseContextProps<T> {
  response?: T
  refetch: () => void
  isLoading: boolean
  query?: string
}

export interface LoadMoreQueryResponseContextProps<T>
  extends PaginationQueryResponseContextProps<T> {
  setResponse?: (response?: T) => void
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export interface ListViewContextProps {
  hoveredIndex: number
  selected: String[]
  onHover: (hoveredIndex: number) => void
  onSelect: (selectedId: String) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: String
  setItemIdForUpdate: Dispatch<SetStateAction<String | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  hoveredIndex: -9999,
  selected: [],
  onHover: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}
