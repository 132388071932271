/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItem
      to='/upgrade-account'
      title='Đối soát đơn hàng nâng cấp đại lý'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
    />
  </Fragment>
)

export {SidebarMenuMain}
